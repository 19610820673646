<main class="Main">
    <!-- <h1>{{""}}</h1> -->
    
    <section  class="Carousel"
              id="carousel" 
              tabindex="-1">
      <h2 class="Hidden">Carousel</h2>


      <article *ngFor="let card of cards"
                class="Card Card--overlay Card--square"
                [id]="'skill-card-' + card.id"
                (click)="showDetail(card)">
        <div class="Card__media">
          <img  class="Card__image"
                [alt]="card.img.alt" 
                [width]="card.img.width"
                [height]="card.img.height" 
                loading="lazy"
                [src]="card.img.src">
        </div>
        <div class="Card__main">
          <h2 class="Card__heading">
            <a class="Card__link" href="#">
                {{card.content.title}}
            </a>
        </h2>
          <p>{{card.content.subtitle}}</p>
        </div>
      </article>
      <!--/Card-->
    </section>
    <!--/Carousel-->
    
    <nav class="Pagination">
      <h2 class="Hidden">Carousel Navigation</h2>
      <button class="Arrow Prev disabled" type="button" aria-controls="carousel-prev" disabled>
        <svg width="16" height="16" viewBox="0 0 16 16" role="presentation">
          <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"/>
        </svg>
        <span class="Hidden">Previous slide</span>
      </button>
      <button class="Arrow Next" type="button" aria-controls="carousel-next" disabled>
        <svg width="16" height="16" viewBox="0 0 16 16" role="presentation">
          <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
        </svg>
        <span class="Hidden">Next slide</span>
      </button>
      <div class="Dots">
        <a *ngFor="let card of cards"
            [href]="'#card-' + card.id"
            class="Dot" 
            tabindex="-1">
            <span class="Hidden">Slide {{card.id}}</span>
        </a>
    </div>
    </nav>
    <!--/Pagination-->
  </main>
  <!--/Main-->