export const environment = {
    production: true,
    firebase: {
        apiKey: "AIzaSyCK64VTEwj8Zk81YvBTjXhpWO3k3jkXZw4",
        authDomain: "portafolio-ec309.firebaseapp.com",
        databaseURL: "https://portafolio-ec309.firebaseio.com",
        projectId: "portafolio-ec309",
        storageBucket: "portafolio-ec309.appspot.com",
        messagingSenderId: "628360888572",
        appId: "1:628360888572:web:9dfd5136af09be57bc9779",
        measurementId: "G-XQ2EGJKBCJ"
    },
    firestore: {
        collectionName: 'data'
    },
    cookie : {
        name: 'DABELOPER'
    }
};