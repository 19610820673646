<li class="l-section section section--is-active">
    <div class="home">
      <div class="home-banner">
        <h1 *ngIf="!!typings">{{ 'home.hello' | translate }},<br>
          <ngx-typed-js
            [strings]="typings"
            [loop]="false"
            [shuffle]="false"
            [typeSpeed]="100"
            [backSpeed]="20"
          >
            <span class="typing"></span>
          </ngx-typed-js>
        </h1>

        <button class="cta" (click)="analytics.trackHomeBtn()">{{ "home.pingMe" | translate }}
          <!-- <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 150 118" style="enable-background:new 0 0 150 118;" xml:space="preserve">
          <g transform="translate(0.000000,118.000000) scale(0.100000,-0.100000)">
            <path d="M870,1167c-34-17-55-57-46-90c3-15,81-100,194-211l187-185l-565-1c-431,0-571-3-590-13c-55-28-64-94-18-137c21-20,33-20,597-20h575l-192-193C800,103,794,94,849,39c20-20,39-29,61-29c28,0,63,30,298,262c147,144,272,271,279,282c30,51,23,60-219,304C947,1180,926,1196,870,1167z"/>
          </g>
          </svg> -->
          <span class="btn-background"></span>
        </button>
        <img src="assets/img/introduction-visual.png" alt="{{ 'home.welcome' | translate }}">
      </div>
      <div class="home-options">
        <a id="home-card-left">
          <h3> {{ "home.titleHomeOption1" | translate }}
            <img alt="CO"
                  src="http://purecatamphetamine.github.io/country-flag-icons/3x2/CO.svg"/>
          </h3>
          <div>
            <p [innerHTML]="'home.subtitleHomeOption' | translate"></p>
            <p *ngIf="userCountry">
              {{ "home.subtitle1HomeOption1" | translate: {country: userCountry} }} <img alt="CO" [src]="'http://purecatamphetamine.github.io/country-flag-icons/3x2/'+userShortCountry+'.svg'"/>
              {{ ( userShortCountry === 'CO' ? "home.subtitleSamePlaceHomeOption1" : "home.subtitleCoolPlaceHomeOption1") | translate : { country: userCountry } }}
              {{ "home.subtitle2HomeOption1" | translate }}
            </p>
          </div>
        </a>
        <a id="home-card-center">
          <h3> {{ "home.titleHomeOption2" | translate }} </h3>
          <p> {{ "home.subtitleHomeOption2" | translate }} </p>
        </a>
        <a id="home-card-right">
          <h3> {{ "home.titleHomeOption3" | translate }} </h3>
          <p> {{ "home.subtitleHomeOption3" | translate }} </p>
        </a>
      </div>
    </div>
  </li>