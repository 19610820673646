<li class="l-section section">
    <div class="skills">
      <div class="skills-banner">
        <h2 *ngIf="!!selectedCard?.content?.title; else selectTitle">
          {{ selectedCard?.content?.title }}
        </h2>
        <ng-template #selectTitle>
          <h2 *ngIf="!!typings">
            <ngx-typed-js
              [strings]="typings"
              [loop]="true"
              [shuffle]="false"
              [typeSpeed]="100"
              [backSpeed]="20"
            >
              <span class="typing"></span>
            </ngx-typed-js>
            
          </h2>
        </ng-template>
        
          
        <p>
          {{ selectedCard?.content?.description }}
        </p>
        <!-- <a href="#0"> ARROW LEFT
          <span>
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 150 118" style="enable-background:new 0 0 150 118;" xml:space="preserve">
            <g transform="translate(0.000000,118.000000) scale(0.100000,-0.100000)">
              <path d="M870,1167c-34-17-55-57-46-90c3-15,81-100,194-211l187-185l-565-1c-431,0-571-3-590-13c-55-28-64-94-18-137c21-20,33-20,597-20h575l-192-193C800,103,794,94,849,39c20-20,39-29,61-29c28,0,63,30,298,262c147,144,272,271,279,282c30,51,23,60-219,304C947,1180,926,1196,870,1167z"/>
            </g>
            </svg>
          </span>
        </a> -->
        <img  [src]="selectedCard?.img?.src || ''"
              [alt]="selectedCard?.img?.alt || ''"
              [height]="selectedCard?.img?.height || ''">
      </div>
      <div *ngIf="!!dabeloper.skills" 
            class="skills-options">
        <app-carousel 
              [cards]="dabeloper.skills"
              (selectedCardEmitter)="selected($event)"></app-carousel>
      </div>
    </div>
  </li>