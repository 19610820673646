<li class="l-section section">
    <div class="hire">
      <canvas #confetti
              [width]="conffetiAnimation ? viewWidth : 0"
              [height]="conffetiAnimation ? viewHeight : 0">
      </canvas>
      <h2 *ngIf="!!typings">
        <span *ngIf="!showTryAgain">
          {{ "check.looking" | translate }}
        </span>
        <ngx-typed-js
          *ngIf="showTryAgain"
          [strings]="typings"
          [loop]="false"
          [shuffle]="false"
          [typeSpeed]="100"
          [backSpeed]="20"
        >
          <span class="typing"></span>
        </ngx-typed-js>
      </h2>

      <!-- checkout formspree.io for easy form setup -->
      <div class="work-request">
        <div class="work-request--options">
          <span *ngIf="optionsA"
                class="options-a">
            <ng-container *ngFor="let opt of optionsA; let i=index">
              <input [id]="'opt-' + i" type="checkbox" [value]="opt" (change)="checkOption(i, $event)">
              <label [for]="'opt-' + i" [style.z-index]="showTryAgain ? 0 : 1000">
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 150 111" style="enable-background:new 0 0 150 111;" xml:space="preserve">
                <g transform="translate(0.000000,111.000000) scale(0.100000,-0.100000)">
                  <path d="M950,705L555,310L360,505C253,612,160,700,155,700c-6,0-44-34-85-75l-75-75l278-278L550-5l475,475c261,261,475,480,475,485c0,13-132,145-145,145C1349,1100,1167,922,950,705z"/>
                </g>
                </svg>
                {{ opt }}
              </label>
            </ng-container>
          </span>
          <span *ngIf="optionsB"
                class="options-b">
            
            <ng-container *ngFor="let opt of optionsB; let i=index">
              <input [id]="'opt-' + (i+4)" type="checkbox" [value]="opt" (change)="checkOption(i+3, $event)">
              <label [for]="'opt-' + (i+4)" [style.z-index]="showTryAgain ? 0 : 1000">
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 150 111" style="enable-background:new 0 0 150 111;" xml:space="preserve">
                <g transform="translate(0.000000,111.000000) scale(0.100000,-0.100000)">
                  <path d="M950,705L555,310L360,505C253,612,160,700,155,700c-6,0-44-34-85-75l-75-75l278-278L550-5l475,475c261,261,475,480,475,485c0,13-132,145-145,145C1349,1100,1167,922,950,705z"/>
                </g>
                </svg>
                {{ opt }}
              </label>
            </ng-container>
          </span>
        </div>
        <button *ngIf="showTryAgain"
                class="button"
                (click)="checkAgain()">
          <span>
            {{ "check.checkAgain" | translate }}
          </span>
        </button>
        <button *ngIf="!showTryAgain"
                [class]="buttonClass"
                [disabled]="buttonClass != 'button'"
                (click)="checkMyFit()">
          <div *ngIf="buttonClass != 'button'"
                class="loader button__loader"></div>
          <span *ngIf="buttonClass == 'button';else animateText">
            {{ "check.checkIt" | translate }}
          </span>
          <ng-template #animateText>
              <ngx-typed-js
                [strings]="[checkingStg]"
                [loop]="false"
                [shuffle]="false"
                [typeSpeed]="200"
                [showCursor]="false"
                (completed)="showConfetti()"
              >
                <span class="typing"></span>
              </ngx-typed-js>
          </ng-template>
        </button>
      </div>
    </div>
  </li>