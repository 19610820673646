export const AnalyticsEvents: any = {
    MENU: "MENU",
    MENU_HOME: "MENU_HOME",
    MENU_SKILLS: "MENU_SKILLS",
    MENU_WORKS: "MENU_WORKS",
    MENU_CHECKLIST: "MENU_CHECKLIST",
    MENU_CONTACT: "MENU_CONTACT",
    HOME_PINGME: "HOME_PINGME",
    SKILL_CLICKED: "SKILL_CLICKED",
    WORK_CLICKED: "WORK_CLICKED",
    CHECKLIST_SELECTED: "CHECKLIST_SELECTED",
    CHECKLIST_VALIDATE: "CHECKLIST_VALIDATE",
    CHECKLIST_CHECK_AGAIN: "CHECKLIST_CHECK_AGAIN",
    CONTACT_EMAIL: "CONTACT_EMAIL",
    CONTACT_LINKEDIN: "CONTACT_LINKEDIN",
    CONTACT_INSTAGRAM: "CONTACT_INSTAGRAM",
    CONTACT_FACEBOOK: "CONTACT_FACEBOOK",
    CONTACT_TWITTER: "CONTACT_TWITTER",
    CONTACT_GITHUB: "CONTACT_GITHUB",
    CONTACT_CONTACTME: "CONTACT_CONTACTME",
    CONTACT_MAP: "CONTACT_MAP"
}